/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init

        $('.home-hero-slides').slick({
          infinite: true,
          cssEase: 'linear',
          autoplay: true,
          arrows: false,
          dots: false,
          fade: true
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'xtimeline': {
      init: function() {
        
        $(function(){
          var boxes = $('.timeline-column'),   
          $window = $(window);
          
          $window.scroll(function(){
            var scrollTop = $window.scrollTop();
            console.log($window.scrollTop());
            boxes.each(function(){
              var $this = $(this),
                  scrollspeed = parseInt($this.data('scroll-speed')),
                  val = - scrollTop / scrollspeed;
              $this.css('transform', 'translateY(' + val + 'px)');
            });
          });
        })

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

document.addEventListener("DOMContentLoaded", function () {
  //Main Nav toggle
  const nav = document.querySelector("#site-nav");
  const header = document.querySelector(".main-header");
  const opener = document.querySelector(".nav-opener");

  opener.addEventListener("click", function (event) {
    event.preventDefault();

    header.classList.toggle("nav-open");
    nav.classList.toggle("active");
  });
});

